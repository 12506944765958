<!--
 * @Descripttion:
 * @Author: 张思波【4640】
 * @Date: 2021-10-07 18:02:02
 * @LastEditors: 张思波【4640】
 * @LastEditTime: 2021-10-08 10:48:44
-->
<template>
  <div id="app" v-cloak>
    <div class="page">
      <PageHeader/>
      <div class="page-content-wrapper" :class="{'page-content-wrapper--no-footer': $route.meta.noFooter}">
        <router-view/>
      </div>
      <PageFooter v-show="!$route.meta.noFooter"/>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import PageHeader from './components/PageHeader'
import PageFooter from './components/PageFooter'

export default {
  name: 'App',
  provide() {
    return {
      resolution: () => this.resolutionData,
      case: () => this.caseData,
      caseOrigin: () => this.caseOriginData,
      product: () => this.productData,
      news: () => this.newsData,
      ready: () => this.ready
    }
  },
  components: {
    PageHeader,
    PageFooter
  },
  computed: {
    ready() {
      return { state: this.resolutionReady && this.newsReady && this.caseReady }
    }
  },
  data() {
    return {
      resolutionData: [],
      caseOriginData: [],
      caseData: [],
      productData: [],
      newsData: [],
      resolutionReady: false,
      caseReady: false,
      newsReady: false
    }
  },
  methods: {
    getProductList() {
      const self = this
      axios.get('/api/Open/GetProductList', { params: { productType: 2 } })
        .then(response => {
          response = response.data
          if (response.Code === '0') {
            self.productData = self.dealProductListData(response.Data.List)
          }
        })
    },
    dealProductListData(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          title: item.Name,
          subTitle: item.ShortName,
          icon: item.Icon,
          link: `/product?id=${item.Id}`
        })
      })
      return result
    },
    getResolutionList() {
      const self = this
      axios.get('/api/Open/GetProductList', { params: { productType: 1 } })
        .then(response => {
          response = response.data
          if (response.Code === '0') {
            self.resolutionData = Object.freeze(self.dealResolutionListData(response.Data.List))
            self.resolutionReady = true
          }
        })
        .catch(e => {
          console.log(e)
          self.resolutionReady = true
        })
    },
    dealResolutionListData(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          title: item.Name,
          subTitle: item.ShortName,
          icon: item.Icon,
          img: item.Pic,
          link: `/resolution?id=${item.Id}`
        })
      })
      return result
    },
    getCaseList() {
      const self = this
      axios.get('/api/Open/GetCaseClassList', { params: { pageIndex: 1, pageSize: 50 } })
        .then(response => {
          response = response.data
          if (response.Code === '0') {
            self.caseData = Object.freeze(self.dealCaseListData(response.Data.List))
            self.caseOriginData = Object.freeze(response.Data.List)
            self.caseReady = true
          }
        })
        .catch(e => {
          console.log(e)
          self.caseReady = true
        })
    },
    dealCaseListData(data) {
      const result = []
      data.forEach(item => {
        if (item.Cases.length) {
          const data = item.Cases[0]
          result.push({
            id: data.Id,
            img: data.Pic,
            title: data.Name,
            desc: data.Summary
          })
        }
      })
      return result
    },
    getNewsTypeList() {
      const self = this
      axios.get('/api/Open/GetNewsTypeList')
        .then(response => {
          response = response.data
          if (response.Code === '0') {
            self.newsData = self.dealNewsTypeList(response.Data)
            self.newsReady = true
          }
        })
        .catch(error => {
          console.log(error)
          self.newsReady = true
        })
    },
    dealNewsTypeList(data) {
      const result = []
      data.forEach(item => {
        result.push({
          id: item.Id,
          img: item.Pic,
          icon: item.Icon,
          title: item.Title,
          link: `/news?type=${item.Id}`
        })
      })
      return result
    }
  },
  created() {
    this.getProductList()
    this.getResolutionList()
    this.getNewsTypeList()
    this.getCaseList()
  }
}
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
  .page {
    width: 100%;
    .page-content-wrapper {
      min-height: calc(100vh - 342px);
      &--no-footer {
        min-height: 100vh;
      }
    }
  }
}
</style>
