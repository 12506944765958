<template>
  <div class="nav-sub-section">
    <ul class="sub-nav-list">
      <li
        v-for="item in data"
        :key="item.id"
      >
        <a :href="item.link" class="sub-list-item">
          <img class="sub-item-icon" v-lazy="item.icon" />
          <span class="in-one-line sub-item-text">{{ item.title }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HomeNavNews',
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.nav-sub-section {
  padding: 20px 100px;
  .sub-nav-list {
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .sub-list-item {
      width: 240px;
      height: 72px;
      padding: 16px;
      margin: 4px;
      border-radius: 4px;
      color: #E1E1E1;
      display: flex;
      align-items: center;
      transition: all .15s ease-in-out;
      .sub-item-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .sub-item-text {
        width: 100%;
      }
      &:hover {
        background-color: rgba(255, 255, 255, .05);
        color: #FFFFFF;
      }
    }
  }
}
</style>
