<template>
  <footer class="page-footer">
    <div class="footer-content">
      <div class="contact-area">
        <img class="qrcode" src="../assets/img/ewm.jpg"/>
        <div class="company-info">
          <div class="info-tel">公司电话：0769-38978079</div>
          <div class="info-email">公司邮箱：support@lfang123.com</div>
        </div>
      </div>
      <div class="nav-area">
        <div
          class="nav-group"
          v-for="(value, key) in navData"
          :key="key"
        >
          <div
            class="nav-item"
            :class="{ 'nav-item--main': index === 0 }"
            v-for="(item, index) in value"
            :key="item.label"
          >
            <router-link :to="item.value" v-if="!item.outer">{{ item.label }}</router-link>
            <a :href="item.value" target="_blank" v-else>{{ item.label }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="copyright-content">
        <div class="content-left">Copyright © 2021.零方信息科技 All rights reserved.</div>
        <a class="content-right" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">蜀ICP备2021001600号</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
  inject: ['resolution', 'product', 'news', 'caseOrigin'],
  computed: {
    resolutionData() {
      return this.resolution()
    },
    productData() {
      return this.product()
    },
    newsData() {
      return this.news()
    },
    caseOriginData() {
      return this.caseOrigin()
    },
    navData() {
      return {
        intro: [
          {
            label: '公司介绍',
            value: '/company'
          },
          {
            label: '公司简介',
            value: '/company/introduce',
            name: 'CompanyIntroduce'
          },
          {
            label: '企业理念',
            value: '/company/concept',
            name: 'CompanyConcept'
          },
          {
            label: '联系我们',
            value: '/company/contact',
            name: 'CompanyContact'
          }
        ],
        resolution: this.dealResolutionListData(this.resolutionData),
        product: this.dealProductListData(this.productData),
        case: this.dealCaseData(this.caseOriginData),
        news: this.dealNewsTypeData(this.newsData),
        plan: [
          {
            label: '星河计划',
            value: 'https://open.myj.com.cn/home/#/milkywayplan',
            outer: true
          }
        ]
      }
    }
  },
  methods: {
    dealResolutionListData(data) {
      const result = [
        {
          label: '解决方案',
          value: '/resolution'
        }
      ]
      data.slice(0, 3).forEach(item => {
        result.push({
          label: item.title,
          value: `/resolution?id=${item.id}`
        })
      })
      result.push({
        label: '更多方案...',
        value: '/resolution'
      })
      return result
    },
    dealProductListData(data) {
      const result = [
        {
          label: '产品介绍',
          value: '/product'
        }
      ]
      data.slice(0, 3).forEach(item => {
        result.push({
          label: item.title,
          value: `/product?id=${item.id}`
        })
      })
      result.push({
        label: '更多产品...',
        value: '/product'
      })
      return result
    },
    dealCaseData(data) {
      const result = [
        {
          label: '客户案例',
          value: { name: 'Case' }
        }
      ]
      data.slice(0, 3).forEach(item => {
        result.push({
          label: item.Name,
          value: { name: 'Case' }
        })
      })
      result.push({
        label: '更多案例...',
        value: { name: 'Case' }
      })
      return result
    },
    dealNewsTypeData(data) {
      const result = [
        {
          label: '零方资讯',
          value: { name: 'News' }
        }
      ]
      data.slice(0, 3).forEach(item => {
        result.push({
          label: item.title,
          value: { name: 'News', query: { type: item.id } }
        })
      })
      result.push({
        label: '更多资讯...',
        value: { name: 'News' }
      })
      return result
    }
  }
}
</script>

<style lang="less" scoped>
.page-footer {
  width: 100%;
  background-color: #393939;
  color: #CCCCCC;
  .footer-content {
    width: 1000px;
    height: 300px;
    padding-top: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .contact-area {
      .qrcode {
        width: 100px;
        height: 100px;
        margin-left: 20px;
      }
      .company-info {
        margin-top: 20px;
        .info-tel {
          font-size: 12px;
          line-height: 30px;
        }
        .info-email {
          font-size: 12px;
          line-height: 30px;
        }
      }
    }
    .nav-area {
      padding-right: 10px;
      display: flex;
      .nav-group {
        width: 120px;
        padding-left: 40px;
        font-size: 14px;
        .nav-item {
          line-height: 36px;
          a {
            white-space: nowrap;
          }
          &:hover {
            color: #FFA73D;
          }
          &--main {
            font-weight: 600;
          }
          .router-link {
            color: #CCCCCC;
          }
        }
        .router-link-active {
          color: inherit;
        }
      }
    }
  }
  .copyright {
    width: 100%;
    height: 42px;
    background-color: #303030;
    color: #FFFFFF;
    font-size: 12px;
    .copyright-content {
      width: 1000px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
