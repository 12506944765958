/*
 * @Descripttion:
 * @Author: 张思波【4640】
 * @Date: 2021-10-07 18:02:00
 * @LastEditors: 鲍红远【300271】
 * @LastEditTime: 2022-01-04 11:39:12
 */
import Vue from 'vue'

import axios from 'axios'
import MetaInfo from 'vue-meta-info'
import { Message, Pagination, Loading, Tag } from 'element-ui'
import VueLazyload from 'vue-lazyload'

import App from './App.vue'
import router from './router'

import 'swiper/css/swiper.min.css'
import './assets/css/reset.css'
// import './assets/css/css.css'
import './assets/css/icon.css'
import './assets/css/common.css'

if (process.env.VUE_APP_API_URL) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
}
Vue.prototype.$message = Message
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(Tag)
Vue.use(MetaInfo)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/img/lazy.png'),
  loading: require('./assets/img/lazy.png'),
  attempt: 1
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted() {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 10000)
  }
}).$mount('#app')
