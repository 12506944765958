import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('../views/Home.vue'/* webpackChunkName:"Home" */)
const Search = () => import('../views/Search.vue'/* webpackChunkName:"Home" */)
const Case = () => import('../views/Case.vue'/* webpackChunkName:"Home" */)
const CaseDetail = () => import('../views/CaseDetail.vue'/* webpackChunkName:"Home" */)

const Company = () => import('../views/Company.vue'/* webpackChunkName:"Company" */)
const CompanyIntroduce = () => import('../views/CompanyIntroduce.vue'/* webpackChunkName:"Company" */)
const CompanyConcept = () => import('../views/CompanyConcept.vue'/* webpackChunkName:"Company" */)
const CompanyContact = () => import('../views/CompanyContact.vue'/* webpackChunkName:"Company" */)

const Resolution = () => import('../views/Resolution.vue'/* webpackChunkName:"Resolution" */)
const ResolutionDescription = () => import('../views/ResolutionDescription.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionRetail = () => import('../views/ResolutionRetail.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionMember = () => import('../views/ResolutionMember.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionStorehouse = () => import('../views/ResolutionStorehouse.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionSupply = () => import('../views/ResolutionSupply.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionGroupBuying = () => import('../views/ResolutionGroupBuying.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionTakeout = () => import('../views/ResolutionTakeout.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionMedicine = () => import('../views/ResolutionMedicine.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionWholesale = () => import('../views/ResolutionWholesale.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionData = () => import('../views/ResolutionData.vue'/* webpackChunkName:"Resolution" */)
// const ResolutionPay = () => import('../views/ResolutionPay.vue'/* webpackChunkName:"Resolution" */)
const ResolutionDetail = () => import('../views/ResolutionDetail.vue'/* webpackChunkName:"Resolution" */)

const Product = () => import('../views/Product.vue'/* webpackChunkName:"Product" */)
const ProductDescription = () => import('../views/ProductDescription.vue'/* webpackChunkName:"Product" */)
// const ProductBusiness = () => import('../views/ProductBusiness.vue'/* webpackChunkName:"Product" */)
// const ProductTrade = () => import('../views/ProductTrade.vue'/* webpackChunkName:"Product" */)
// const ProductStore = () => import('../views/ProductStore.vue'/* webpackChunkName:"Product" */)
// const ProductMember = () => import('../views/ProductMember.vue'/* webpackChunkName:"Product" */)
// const ProductOffice = () => import('../views/ProductOffice.vue'/* webpackChunkName:"Product" */)
// const ProductFinance = () => import('../views/ProductFinance.vue'/* webpackChunkName:"Product" */)
// const ProductLife = () => import('../views/ProductLife.vue'/* webpackChunkName:"Product" */)
// const ProductLogistics = () => import('../views/ProductLogistics.vue'/* webpackChunkName:"Product" */)
// const ProductMedicine = () => import('../views/ProductMedicine.vue'/* webpackChunkName:"Product" */)
// const ProductMerchandise = () => import('../views/ProductMerchandise.vue'/* webpackChunkName:"Product" */)
// const ProductTakeout = () => import('../views/ProductTakeout.vue'/* webpackChunkName:"Product" */)
// const ProductRetail = () => import('../views/ProductRetail.vue'/* webpackChunkName:"Product" */)
// const ProductProduce = () => import('../views/ProductProduce.vue'/* webpackChunkName:"Product" */)
// const ProductSettlement = () => import('../views/ProductSettlement.vue'/* webpackChunkName:"Product" */)
// const ProductDelivery = () => import('../views/ProductDelivery.vue'/* webpackChunkName:"Product" */)
// const ProductData = () => import('../views/ProductData.vue'/* webpackChunkName:"Product" */)
const ProductDetail = () => import('../views/ProductDetail.vue'/* webpackChunkName:"Product" */)

const News = () => import('../views/News.vue'/* webpackChunkName:"News" */)
const NewsDetail = () => import('../views/NewsDetail.vue'/* webpackChunkName:"News" */)

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/company',
      component: Company,
      children: [
        {
          path: '',
          redirect: 'introduce'
        },
        {
          path: 'introduce',
          name: 'CompanyIntroduce',
          component: CompanyIntroduce,
          meta: { noFooter: true }
        },
        {
          path: 'concept',
          name: 'CompanyConcept',
          component: CompanyConcept,
          meta: { noFooter: true }
        },
        {
          path: 'contact',
          name: 'CompanyContact',
          component: CompanyContact,
          meta: { noFooter: true }
        }
      ]
    },
    {
      path: '/resolution',
      component: Resolution,
      children: [
        // {
        //   path: '',
        //   redirect: 'description'
        // },
        {
          path: '',
          name: 'ResolutionDescription',
          component: ResolutionDescription,
          meta: { noFooter: true }
        }
        // {
        //   path: 'retail',
        //   name: 'ResolutionRetail',
        //   component: ResolutionRetail,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'member',
        //   name: 'ResolutionMember',
        //   component: ResolutionMember,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'storehouse',
        //   name: 'ResolutionStorehouse',
        //   component: ResolutionStorehouse,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'supply',
        //   name: 'ResolutionSupply',
        //   component: ResolutionSupply,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'group-buying',
        //   name: 'ResolutionGroupBuying',
        //   component: ResolutionGroupBuying,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'takeout',
        //   name: 'ResolutionTakeout',
        //   component: ResolutionTakeout,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'medicine',
        //   name: 'ResolutionMedicine',
        //   component: ResolutionMedicine,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'wholesale',
        //   name: 'ResolutionWholesale',
        //   component: ResolutionWholesale,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'data',
        //   name: 'ResolutionData',
        //   component: ResolutionData,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'pay',
        //   name: 'ResolutionPay',
        //   component: ResolutionPay,
        //   meta: { noFooter: true }
        // }
      ]
    },
    {
      path: '/product',
      component: Product,
      children: [
        {
          path: '',
          name: 'ProductDescription',
          component: ProductDescription,
          meta: { noFooter: true }
        }
        // {
        //   path: 'business',
        //   name: 'ProductBusiness',
        //   component: ProductBusiness,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'trade',
        //   name: 'ProductTrade',
        //   component: ProductTrade,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'store',
        //   name: 'ProductStore',
        //   component: ProductStore,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'member',
        //   name: 'ProductMember',
        //   component: ProductMember,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'office',
        //   name: 'ProductOffice',
        //   component: ProductOffice,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'finance',
        //   name: 'ProductFinance',
        //   component: ProductFinance,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'life',
        //   name: 'ProductLife',
        //   component: ProductLife,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'logistics',
        //   name: 'ProductLogistics',
        //   component: ProductLogistics,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'medicine',
        //   name: 'ProductMedicine',
        //   component: ProductMedicine,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'merchandise',
        //   name: 'ProductMerchandise',
        //   component: ProductMerchandise,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'takeout',
        //   name: 'ProductTakeout',
        //   component: ProductTakeout,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'retail',
        //   name: 'ProductRetail',
        //   component: ProductRetail,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'produce',
        //   name: 'ProductProduce',
        //   component: ProductProduce,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'settlement',
        //   name: 'ProductSettlement',
        //   component: ProductSettlement,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'delivery',
        //   name: 'ProductDelivery',
        //   component: ProductDelivery,
        //   meta: { noFooter: true }
        // },
        // {
        //   path: 'data',
        //   name: 'ProductData',
        //   component: ProductData,
        //   meta: { noFooter: true }
        // }
      ]
    },
    {
      path: '/news',
      name: 'News',
      component: News
    },
    {
      path: '/product/detail',
      name: 'ProductDetail',
      component: ProductDetail,
      meta: { detail: true }
    },
    {
      path: '/resolution/detail',
      name: 'ResolutionDetail',
      component: ResolutionDetail
    },
    {
      path: '/news/detail',
      name: 'NewsDetail',
      component: NewsDetail
    },
    {
      path: '/search',
      name: 'Search',
      component: Search
    },
    {
      path: '/case',
      name: 'Case',
      component: Case
    },
    {
      path: '/case/detail',
      name: 'CaseDetail',
      component: CaseDetail
    }
  ]
})

export default router
