<template>
  <header class="page-header" :class="{ 'page-header--detail': $route.meta.detail }">
    <div class="header-content">
      <div class="header-logo">
        <div class="logo">
          <img src="../assets/img/logo.png" @click.stop="$router.push({ name: 'Home' })"/>
        </div>
        <div class="logo-icon">
          <img src="../assets/img/icon_home.png" @click.stop="$router.push({ name: 'Home' })"/>
        </div>
      </div>
      <div class="header-nav">
        <ul class="nav-list">
          <li
            class="list-item"
            :class="{ 'list-item--active': $route.name && $route.name.indexOf(item.name) > -1 }"
            v-for="item in navData"
            :key="item.label"
          >
            <router-link class="item-text" :to="item.value" v-if="item.name">{{ item.label }}</router-link>
            <a class="item-text" :href="item.value" target="_blank" v-else>{{ item.label }}</a>
            <div class="list-item-second" v-if="['Product', 'Resolution', 'News'].includes(item.name)">
              <keep-alive>
                <component v-bind:is="`HomeNav${item.name}`" :data="getNavData(item.name)" />
              </keep-alive>
            </div>
          </li>
        </ul>
        <div class="search-area-wrapper">
          <div class="search-area" v-if="$route.name !== 'Search'">
            <div class="search-input">
              <input type="text" v-model="keyword" @keyup.enter.stop="toSearchPage"/>
            </div>
            <div class="search-icon" @click.stop="toSearchPage">
              <i class="icon-search"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import HomeNavProduct from './HomeNavProduct'
import HomeNavResolution from './HomeNavResolution'
// import HomeNavCase from './HomeNavCase'
import HomeNavNews from './HomeNavNews'

export default {
  name: 'PageHeader',
  components: {
    HomeNavProduct,
    HomeNavResolution,
    // HomeNavCase,
    HomeNavNews
  },
  data() {
    return {
      navData: Object.freeze([
        {
          label: '公司介绍',
          value: '/company',
          name: 'Company'
        },
        {
          label: '解决方案',
          value: '/resolution',
          name: 'Resolution'
        },
        {
          label: '产品介绍',
          value: '/product',
          name: 'Product'
        },
        {
          label: '客户案例',
          value: '/case',
          name: 'Case'
        },
        {
          label: '零方资讯',
          value: '/news',
          name: 'News'
        },
        {
          label: '星河计划',
          value: 'https://open.myj.com.cn/home/#/milkywayplan'
        }
      ]),
      keyword: ''
    }
  },
  inject: ['resolution', 'product', 'news'],
  computed: {
    resolutionData() {
      return this.resolution()
    },
    productData() {
      return this.product()
    },
    newsData() {
      return this.news()
    }
  },
  methods: {
    toSearchPage() {
      const keyword = this.keyword
      this.$router.push({ name: 'Search', params: { keyword, from: this.$route.name } })
      this.keyword = ''
    },
    getNavData(name) {
      switch (name) {
        case 'Resolution':
          return this.resolutionData
        case 'Product':
          return this.productData
        case 'News':
          return this.newsData
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page-header {
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  height: 100px;
  background-color: rgba(22, 22, 22, .3);
  backdrop-filter: blur(12px);
  &--detail {
    background: #333333;
    backdrop-filter: none;
  }
  .header-content {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-logo {
      margin: 0 8px;
      cursor: pointer;
      display: flex;
      align-items: baseline;
      .logo {
        height: 40px;
        display: flex;
        align-items: center;
        transition: all .2s ease-in-out;
        &:hover {
          opacity: .8;
        }
        &:after {
          content: '';
          width: 0;
          height: 44px;
          margin: 0 8px 0 12px;
          border-right: 1px solid rgba(255, 255, 255, .25);
        }
        img {
          width: auto;
          height: 40px;
        }
      }
      .logo-icon {
        transition: all .2s ease-in-out;
        &:hover {
          opacity: .8;
        }
        img {
          width: auto;
          height: 40px;
        }
      }
    }
    .header-nav {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .nav-list {
        height: 100px;
        display: flex;
        align-items: center;
        .list-item {
          white-space: nowrap;
          font-size: 18px;
          color: #FFFFFF;
          cursor: pointer;
          &:hover {
            color: #FFA73D;
            .list-item-second {
              height: auto;
              //max-height: 100vh;
            }
          }
          .item-text {
            display: block;
            height: 100px;
            padding: 0 25px;
            line-height: 100px;
          }
          .router-link-active {
            color: inherit;
          }
          &--active {
            .router-link-active {
              color: #FFA73D;
            }
          }
        }
        .list-item-second {
          position: absolute;
          top: 100%;
          left: 0;
          width: 1200px;
          //max-height: 0;
          height: 0;
          overflow: hidden;
          border-radius: 0 0 4px 4px;
          background-color: rgba(22, 22, 22, .8);
          //background-color: rgba(22, 22, 22, .3);
          //transition: max-height .3s ease-in;
        }
      }
      .search-area-wrapper {
        width: 144px;
        height: 32px;
        .search-area {
          width: 144px;
          height: 32px;
          padding: 0 12px;
          margin-left: 20px;
          border: 1px #FFFFFF solid;
          border-radius: 16px;
          font-size: 18px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          .search-input {
            flex: 1;
            height: 30px;
            margin-right: 4px;
            input {
              width: 100%;
              height: 30px;
              line-height: 28px;
              background: transparent;
              color: #FFFFFF;
            }
          }
          .search-icon {
            font-size: 18px;
            cursor: pointer;
            &:hover {
              opacity: .8;
            }
          }
        }
      }
    }
  }
}
</style>
